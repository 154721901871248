import {mapGetters} from "vuex";
import FinancialReportsComponent from "@/views/about/components/financial-reports.component.vue";

export default {
    name: 'financial-report',
    components: {
        'financial-reports-component': FinancialReportsComponent
    },
    computed: {
        ...mapGetters({
            local: '_getLocal',
            leads: 'about/_getLeads',
            financialReports: 'about/_getFinancialReports'
        }),
    },
    data(){
        return {
            title: 'hello'
        }
    },
    created() {
        this.$store.dispatch("about/getLeads")
        this.$store.dispatch('about/getFinancialReports')
    }
}