<style lang="scss" src="./financial-reports.component.scss"></style>

<template>
  <div class="financial mt-55">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12" v-for="(elem,index) in items" :key="index">
        <card-component class="py-20 px-20 mb-25">
          <div class="about-currency">
            <div class="about-currency_left">
              <h3>{{elem.title}}</h3>
              <p>{{elem.description}}</p>
            </div>
            <div class="about-currency_right">
              <a download :href="elem.fileUrl">
                <img @click="downloadDoc" :src="require('../../../assets/images/cloud.svg')" alt="">
              </a>
            </div>
          </div>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script lang="js" src="./financial-reports.component.js"></script>
