import CardComponent from "../../../core/shared/card/card.component.vue";

export default {
    name: "financial-reports.component",
    components: {
        'card-component': CardComponent
    },
    props: {
        items:{
            type: Array,
            required: true
        }
    }
}