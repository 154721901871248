<style lang="scss" src="./financial.component.scss"></style>

<template>
  <div class="financial">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="financial-report clearfix mb-35" v-for="(lead, index) of leads" :key="index">
            <img :src="lead.fileUrl" alt="">
            <p>{{ local.projectDetails.ourValue }}</p>
            <h1 class="mt-20 mb-30">{{ lead.title }}</h1>
            <span>{{ lead.description }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <financial-reports-component :items="financialReports"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js" src="./financial.component.js"></script>
